<template>
    <modal ref="caracteristicas" titulo="Caracteristicas del material" tamano="modal-md">
        <div class="row justify-content-center">
            <div v-if="Object.entries(material).length" class="col-12">
                <div class="py-3">
                    <el-select v-model="bodegasSelect" @change="handle" clearable multiple placeholder="Seleccionar bodegas" size="small" class="w-100">
                        <el-option v-for="(bodega, index) in inventario_material"
                            :key="index"
                            :label="bodega.nombre"
                            :value="bodega.id"
                        /> 
                    </el-select>
                    <div class="row justify-content-center my-2 mt-4">
                        <div class="col-5 text-right">
                            <p class="text-general f-600 f-14">Nombre material</p>
                        </div>
                        <div class="col-6">
                            <p class="f-12">{{ material.nombre }}</p>
                        </div>
                    </div>
                    <div class="row justify-content-center my-2">
                        <div class="col-5 text-right">
                            <p class="text-general f-600 f-14">Cantidad</p>
                        </div>
                        <div class="col-6">
                            <p class="f-12">{{ material.cantidad }}</p>
                        </div>
                    </div>
                    <div v-for="(proveedor, index) in proveedores" :key="index" class="row justify-content-center my-2 mt-4">
                        <div class="col-5 my-auto text-right">
                            <p class="text-general f-600 f-14">{{ proveedor.proveedor }}</p>
                        </div>
                        <div class="col-6">
                            <div v-if="proveedor.materiales.length" class="d-middle">
                                <el-input-number
                                    v-model="proveedor.materiales[proveedor.index].cantidad"
                                    size="small"
                                    placeholder="cantidad"
                                    class="w-50"
                                    :controls="false"
                                    :min="0"
                                    :max="(material.cantidad - getTotal)+proveedor.materiales[proveedor.index].cantidad"
                                />
                                <div class="text-general f-12 ml-3 text-center">
                                    <p class="border-bottom-g">Valor por Unidad</p>
                                    <p>{{ formatoMoneda(proveedor.materiales[0].valor) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="bg-f6">
                        <div v-for="(bodega, index) in bodegasTable" :key="index">
                            <div v-if="bodega.index != -1" class="row justify-content-center my-2">
                                <div class="col-5 my-auto text-right">
                                    <p class="text-general f-600 f-14">{{ bodega.nombre }}</p>
                                </div>
                                <div class="col-6">
                                    <div v-if="bodega.materiales.length" class="d-middle">
                                        <el-input-number
                                            v-model="bodega.materiales[bodega.index].cantidad"
                                            size="small"
                                            placeholder="cantidad"
                                            class="w-50"
                                            :controls="false"
                                            :min="0"
                                            :max="getMaxBodega(bodega.materiales[bodega.index].disponible, bodega.materiales[bodega.index].cantidad)"
                                        />
                                        <div class="text-general f-12 ml-3 text-center">
                                            <p class="border-bottom-g">Cant. disponible</p>
                                            <p>{{ bodega.materiales[bodega.index].disponible }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return {
            material: {},
            proveedores: [],
            bodegasTable: [],
            bodegasSelect: []
        }
    },
    computed: {
        ...mapGetters({
            inventario_material: 'obras/ordenesCompras/inventario_material',
            proveedores_select: 'obras/ordenesCompras/proveedores_select'
        }),
        getTotal(){
            let total = 0
            this.proveedores.forEach(el => {
                total += el.materiales[el.index]?.cantidad ?? 0
            })
            this.bodegasTable.forEach(el => {
                if (el.index != -1) {
                    total += el.materiales[el.index]?.cantidad ?? 0
                }
            })
            return total
        }
    },
    watch: {
        bodegasSelect(newVal, oldVal) {
            if (newVal.length > oldVal.length) {
                this.agregarBodega(this.inventario_material.find(el => el.id == newVal[newVal.length-1]))
            } else if (newVal.length < oldVal.length) {
                this.removerBodega(this.inventario_material.find(el => el.id == oldVal.filter(f => !newVal.includes(f))[0]))
            }
        },
        proveedores_select(){
            this.proveedores.forEach((el, idxProv) => {
                el.materiales.forEach((ele, idxMaterial) => {
                    ele.valor = this.proveedores_select[idxProv].materiales[idxMaterial].valor
                })
            })
        },
        cambioCantidad(){
            this.Action_get_valor_materiales_proveedores({proveedores: this.proveedores, bodegas: this.bodegasTable})
        }
    },
    methods: {
        ...mapActions({
            Action_get_inventario_material: 'obras/ordenesCompras/Action_get_inventario_material',
            Action_get_valor_materiales_proveedores: 'obras/ordenesCompras/Action_get_valor_materiales_proveedores',
        }),
        async toggle(material, proveedores, bodegas){
            this.material = material
            this.proveedores = proveedores
            this.bodegasTable = bodegas
            this.proveedores.forEach(el => {
                el.index = el.materiales.findIndex(f => this.material.id == f.id)
                if (el.index == -1) {
                    el.materiales.push({id: this.material.id, cantidad: 0, valor: 0})
                    el.index = el.materiales.length-1
                }
            })
            if (this.bodegasTable.length) {
                this.bodegasTable.forEach(el => {
                    el.index = el.materiales.findIndex(f => this.material.id == f.id)
                    if (el.index == -1) {
                        el.materiales.push({id: this.material.id, cantidad: 0, valor: 0})
                        el.index = el.materiales.length-1
                    }
                })
                this.bodegasSelect = []
                this.bodegasTable.forEach(el => {
                    if (el.materiales.some(so => so.id == this.material.id)) this.bodegasSelect.push(el.id)
                })
            }
            await this.inventarioBodegas()
            this.$refs.caracteristicas.toggle()
        },
        async handle(e){
            await this.Action_get_valor_materiales_proveedores({proveedores: this.proveedores, bodegas: this.bodegasTable})
        },
        agregarBodega(bodega){
            let indexBodega = this.bodegasTable.findIndex(f => f.id == bodega.id)
            if (indexBodega == -1) {
                this.bodegasTable.push({...bodega, index: 0, materiales: []})
                indexBodega = this.bodegasTable.length-1
            } else {
                if (this.bodegasTable[indexBodega].materiales.some(so => so.id == this.material.id)) return
            }
            this.bodegasTable[indexBodega].disponible = bodega.disponible
            this.bodegasTable[indexBodega].index = this.bodegasTable[indexBodega].materiales.length
            this.bodegasTable[indexBodega].materiales.push({id: this.material.id, cantidad: 0, disponible: this.bodegasTable[indexBodega].disponible, valor:this.bodegasTable[indexBodega].valor})
        },
        removerBodega(bodega){
            let indexBodega = this.bodegasTable.findIndex(f => f.id == bodega.id)
            this.bodegasTable[indexBodega].materiales = this.bodegasTable[indexBodega].materiales.filter(f => f.id != this.material.id)
            this.bodegasTable[indexBodega].index = -1
        },
        async inventarioBodegas(){
            await this.Action_get_inventario_material(this.material.id)
        },
        getMaxBodega(disponible, val){
            let maximo = this.material.cantidad - this.getTotal
            return disponible
                ? disponible >= maximo
                    ? maximo + val
                    : disponible
                : 0
        }
        // includeBodega(idBodega){
        //     const index = this.bodegasTable.findIndex( el => (el.id == idBodega && el.materiales.some(so => so.id == this.material.id) ))
        //     if (index === -1) return false
        //     return true
        // }
    }
}
</script>

<style lang="css" scoped>

</style>
