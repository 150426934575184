<template>
    <section class="select-proveedores container-fluid">
        <navbar-admin icon="icon-shopping"  />
        <div class="row my-3 titulo-divisor">
            <!-- regresar -->
            <div class="col-auto my-auto">
                <p class="f-600 f-14 ucfirst"> 
                    <i class="icon-left-open mr-1 cr-pointer" @click="goBack" />
                    Proveedores
                </p>
            </div>
            <div class="col my-auto pl-0">
                <hr class="my-0 bg-white" />
            </div>
            <div class="col-auto my-auto">
                <div class="d-middle-center">
                    <!-- filters -->
                    <filter-indicator :count="filtersCount" @openModalFilter="abrirModalFiltros" @clearFilter="clearAllFilters" />
                    <!-- agregar proveedor -->
                    <div class="col-auto px-1 icon-option">
                        <el-popover v-model="visibleAddSupplier" placement="bottom-end" width="360" trigger="click" class="px-0">
                            <div class="row mx-0 justify-content-center f-600 text-general">
                                Agregar proveedor
                            </div>
                            <hr />
                            <div class="row mx-0 justify-content-center my-2">
                                <div class="col-11 my-2">
                                    <p class="input-label-top">Seleccionar proveedor</p>
                                    <el-select v-model="providerSelect" clearable multiple placeholder="Seleccionar proveedor" size="small" class="w-100">
                                        <el-option v-for="prov in proveedores" :key="prov.id" :label="prov.proveedor" :value="prov.id" /> 
                                    </el-select>
                                </div>
                            </div>
                            <hr />
                            <div class="text-right m-0">
                                <button class="btn btn-cerrar f-12" @click="visibleAddSupplier = false">Cerrar</button>
                                <button class="btn btn-crear f-12 ml-2" @click="addProvider">Guardar</button>
                            </div>
                            <el-tooltip slot="reference" content="Agregar proveedor" effect="light" placement="top" visible-arrow>
                                <i class="icon-plus-circle cr-pointer f-30" />
                            </el-tooltip>
                        </el-popover>
                    </div>
                    <!-- search -->
                    <div class="col-auto my-auto">
                        <el-input v-model="buscar" placeholder="Buscar proveedores OC" size="small" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Enviar oc -->
        <div class="row justify-content-end">
            <div class="col-auto">
                <button
                    class="btn btn-general f-12 px-4"
                    :class="{'not-allowed': !cantidadesOk}"
                    @click="openSendOC">Enviar ordenes de compra</button>
            </div>
        </div>
        <div class="row justify-content-center mx-0 mt-4">
            <div class="col-12 shadow29 border br-4 px-0">
                <el-table :data="materiales.data" style="width: 100%" alturaTabla="500px" max-height="450">
                    <el-table-column fixed width="300" prop="material" label="Nombre material" show-overflow-tooltip >
                        <template slot-scope="scope">
                            <p class="tres-puntos f-12 f-600 cr-pointer" @click="openAgregarBodega(scope.$index)">{{ scope.row.nombre }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="type" label="Cantidad" width="100">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.cantidad" size="small" class="w-100 mx-auto text-center" @input="changeQuantity()"/>
                        </template>
                    </el-table-column>
                    <el-table-column v-for="(item, index) in providersTable" :key="index" :label="item.proveedor" min-width="150" align="center">
                        <template slot-scope="scope" >
                            <div class="text-center f-12">
                                <p>{{ cantidadProveedor(item, scope.row.id) }}</p>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column :label="'Bodegas'" min-width="150" align="center" fixed="right">
                        <template slot-scope="scope">
                                <div class="text-center f-12">
                                    <p>{{ cantidadBodega(scope.row.id) }}</p>
                                </div>
                        </template>
                    </el-table-column>

                    <el-table-column  fixed="right" label="Eliminar" width="90" align="center">
                        <template slot-scope="scope">
                            <i class="icon-trash-can-outline f-22 cr-pointer" @click.stop="openDeleteMaterial(scope.row.id)" />
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="col-12 text-center my-4">
                <Pagination :pagination="materiales.pagination" layout="prev, pager, next" @paginate="listarMateriales"/>
            </div>
        </div>
        <!-- partials -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-4">
                <div class="col-auto">
                    <p class="text-general f-13 f-500">Oportunidad</p>
                </div>
                <div class="col-auto">
                    <p class="f-12 text-center">{{ matriz.oportunidad.oportunidad }}</p>
                </div>
            </div>
            <div class="row mx-0 justify-content-center my-4">
                <div class="col-auto text-center">
                    <img :src="producto.imagen | helper-storage" alt="" width="55">
                </div>
                <div class="col-auto my-auto">
                    <p class="f-12">{{ producto.nombre }}</p>
                </div>
            </div>
            <hr />
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 mb-4">
                    <p class="input-label-top">Proveedor</p>
                    <el-select v-model="filters.id_proveedor" multiple clearable placeholder="Seleccionar proveedor" size="small" class="w-100">
                        <el-option v-for="item in proveedores" :key="item.id" :label="item.proveedor" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Material</p>
                    <el-select v-model="filters.id_material" multiple clearable placeholder="Seleccionar material" size="small" class="w-100">
                        <el-option v-for="item in materiales" :key="item.id" :label="item.material" :value="item.id" />
                    </el-select>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearAllFilters"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filterMatriz"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal-eliminar-material ref="modalEliminarMaterial" @deleteMaterial="deleteMaterialFromList()"/>
        <modal-enviar-ordenes-compra ref="modalEnviarOrdenesCompra" />
        <modal-select-bodegas ref="modalSelectBodegas" />
    </section>
</template>

<script>
import { ProviderFactory } from '~/domain/entities/compras/matriz/factories/ProviderFactory'
import {mapGetters, mapActions} from 'vuex'

import modalEliminarMaterial from './partials/modalEliminarMaterial'
import modalEnviarOrdenesCompra from './partials/modalEnviarOrdenesCompra'
import modalSelectBodegas from './partials/modalSelectBodegas'

export default {
    components:{
        modalEliminarMaterial,
        modalSelectBodegas,
        modalEnviarOrdenesCompra
    },
    data() {
        return {
            matriz: {},
            producto: {},
            configuraciones: [],

            visibleAddSupplier: false,
            buscar: '',
            idEliminar: -1,

            providerSelect: [],
            providersTable: [],
            warehousesTable: [],

            filters: {
                id_material: null,
                id_proveedor: null,
            },
        }
    },
    computed:{
        ...mapGetters({
            proveedores: 'compras/ordenes_compra/proveedores',
            materiales: 'compras/ordenes_compra/materiales',
            bodegas: 'compras/ordenes_compra/bodegas',
        }),

        filtersCount(){
            const { id_material, id_proveedor } = this.filters;
            let count = 0;
            if(id_material) count++;
            if(id_proveedor) count++;
            return count;
        },
        cantidadesOk(){
            let contador = 0;
            let allOK = true;
            this.materiales.data.forEach(el => {
                this.providersTable?.forEach(ele => {
                    ele.materiales?.forEach(elem => {
                        contador += elem.id == el.id ? elem.cantidad : 0
                    })
                })
                this.warehousesTable?.forEach(ele => {
                    ele.materiales?.forEach(elem => {
                        contador += elem.id == el.id ? elem.cantidad : 0
                    })
                })
                if (el.cantidad != contador) allOK = false
                contador = 0
            })
            return allOK
        }
    },
    watch: {
        visibleAddSupplier(val) {
            if (val) this.providerSelect = this.providersTable.map(el => el.id)
        }
    },
    created(){
        const orden_compra = JSON.parse(localStorage.getItem('orden_compra'))
        this.matriz = orden_compra.matriz
        this.producto = orden_compra.producto
        this.configuraciones = orden_compra.configuraciones

        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'compras.matrix',
        ]);
    },
    async mounted(){
        await this.obtenerProveedores()
        await this.obtenerBodegas()
        await this.listarMateriales()
    },
    methods: {
        ...mapActions({
            obtenerProveedores: 'compras/ordenes_compra/Action_get_proveedores',
            obtenerMateriales: 'compras/ordenes_compra/Action_get_materiales',
            obtenerBodegas: 'compras/ordenes_compra/Action_get_bodegas',
        }),
        async listarMateriales(page = 1){
            await this.obtenerMateriales({configuraciones: this.configuraciones, page})
        },
        async clearAllFilters(){
            this.filters = {
                id_material: null,
                id_proveedor: null,
            };
            await this.obtenerMateriales({
                configuraciones: this.configuraciones
            })   
            this.materialClone = this.materiales
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async filterMatriz(){
            await this.obtenerMateriales({id_configuraciones: this.configuraciones.map(conf => conf.id), id_proveedores: this.filters.id_proveedor, id_materiales: this.filters.id_material})   
            this.$refs.abrirModalFiltros.toggle()
        },
        goBack(){
            this.$router.push({ name: 'compras.crear.matriz.etapa.configurar' })
        },
        addProvider(){
            this.providerSelect.forEach(el => {
                let index = this.providersTable.findIndex(f => f.id == el)
                if (index == -1){
                    this.providersTable.push({...this.proveedores.find(ele => ele.id == el ), materiales: []})
                }
            })
            this.providersTable = this.providersTable.filter(f => this.providerSelect.includes(f.id))
            this.visibleAddSupplier = false
        },
        openDeleteMaterial(id){
            this.idEliminar = id
            this.$refs.modalEliminarMaterial.toggle(id)
        },
        deleteMaterialFromList(){
            this.materiales.data = this.materiales.data.filter(material => material.id != this.idEliminar)
        },
        openSendOC(){
            if(!this.cantidadesOk) return;
            
            this.$refs.modalEnviarOrdenesCompra.toggle(this.providersTable, this.warehousesTable)
        },
        openAgregarBodega(idxMaterial){
            const material = this.materiales.data[idxMaterial]
            this.$refs.modalSelectBodegas.toggle(material, this.providersTable, this.warehousesTable)
        },
        cantidadProveedor(proveedor, idMaterial){
            return proveedor?.materiales?.find(el => el.id == idMaterial)?.cantidad ?? 0
        },
        cantidadBodega(idMaterial){
            let total = 0
            this.warehousesTable.forEach(el => {
                total += el.materiales.find(f => f.id == idMaterial)?.cantidad ?? 0
            })
            return total
        },
        changeQuantity(){
        },
    },

}
</script>

<style>

.not-allowed{
    cursor: not-allowed !important;
    opacity: 0.3
}

</style>