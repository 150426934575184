<template>
    <modal ref="modalDelete" titulo="Eliminar material de la OC" adicional="Eliminar" @adicional="deleteMaterial">
        <div class="row mx-0 my-3 justify-content-center f-14">
            <div class="col text-center">
                <p> ¿Está seguro que quiere eliminar este material? {{ obj.id_material }} </p>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            obj: {
                id_material: null,
            }
        }
    },
    methods: {
        toggle(id){
            this.$refs.modalDelete.toggle()
        },
        deleteMaterial(){
            this.$emit('deleteMaterial')
            this.$refs.modalDelete.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
