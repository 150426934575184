<template>
    <modal ref="modalSendOc" titulo="Enviar ordenes de compra" tamano="modal-lg" adicional="Aceptar" @adicional="accept">
        <div class="container-fluid enviar-ordenes">
            <p class="text-general f-12 f-500">Resumen de las ordenes de compra</p>
            <!-- info top -->
            <div class="row info-general justify-content-center py-3 f-12">
                <div class="col-4 my-auto">
                    <div class="d-middle">
                        <div class="wh-74 border-general2 br-4 bg-white">
                            <img src="/img/pruebas/equipo1.png" alt="" class="w-100 obj-cover br-4">
                        </div>
                        <div class="ml-3">
                            <p class="text-general f-12 f-500">Producto:</p>
                            <p class="f-12">{{ productName }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-3 my-auto">
                    <p class="text-general f-12 f-500">Oportunidad:</p>
                    <p class="f-12">{{ oportunity }}</p>
                </div>
                <div class="col-2 my-auto">
                    <p class="text-general f-12 f-500">Fecha entrega:</p>
                    <p class="f-12">{{ matrizDate }}</p>
                </div>
                <div class="col-3 my-auto">
                    <p class="text-general f-12 f-500">Lugar entrega:</p>
                    <p class="f-12">{{ matrizPlace }}</p>
                </div>
            </div>
            <!-- descripcion -->
            <div class="row my-4">
                <div class="col-12">
                    <p class="f-500 f-13">Descripción:</p>
                    <p class="f-12 lh-15">
                        {{ description }}
                    </p>
                </div>
            </div>
            <!-- proveedores -->
            <div class="row bg-f6 shadow29 my-3">
                <p class="f-600 f-14 text-center w-100 py-1">OC Proveedores</p>
            </div>
            <div class="row">
                <div class="col-9">
                    <table class="table f-12 table-borderless table-sm">
                        <thead>
                            <tr class="text-general border-bottom-g">
                                <th scope="col" class="text-center">OC</th>
                                <th scope="col" class="text-center">Nombre</th>
                                <th scope="col" class="text-center">C. materiales</th>
                                <th scope="col" class="text-center">Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data ) in proveedores_select" :key="data.id" class="text-center text-5d">
                                <td>  - </td>
                                <td class="f-600">{{ data.proveedor }}</td>
                                <td> {{ cantidadMateriales(data.materiales) }}</td>
                                <td> {{ formatoMoneda(calcularValorMateriales(data.materiales)) }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-3 my-auto">
                    <div class="border-general br-5">
                        <p class="f-600 text-general f-12 text-center py-2">
                            Valor total
                            <span class="f-300 text-5d ml-1">${{ formatoMoneda(valorTotalProveedores()) }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <!-- bodega -->
            <div class="row bg-f6 shadow29 my-3">
                <p class="f-600 f-14 text-center w-100 py-1">OC Bodegas</p>
            </div>
            <div class="row">
                <div class="col-9">
                    <table class="table f-12 table-borderless table-sm">
                        <thead>
                            <tr class="text-general border-bottom-g">
                                <th scope="col" class="text-center">OC</th>
                                <th scope="col" class="text-center">Nombre</th>
                                <th scope="col" class="text-center">C. materiales</th>
                                <th scope="col" class="text-center">Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data,i) in bodegas" :key="i" class="text-center text-5d">
                                <td> - </td>
                                <td class="f-600">{{ data.nombre }}</td>
                                <td>{{ cantidadMaterialesBodega(data.materiales) }} </td>
                                <td> {{ formatoMoneda(calcularValorMateriales(data.materiales)) }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-3 my-auto">
                    <div class="border-general br-5">
                        <p class="f-600 text-general f-12 text-center py-2">
                            Valor total
                            <span class="f-300 text-5d ml-1">${{ formatoMoneda(valorTotalBodegas()) }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'
export default {
    data(){
        return {
            // valorTotal: 0,
            bodegas:[],
            proveedores:[],
        }
    },
    computed: {
        ...mapGetters({
            proveedores_select: 'obras/ordenesCompras/proveedores_select'
        }),
        materiales(){
            return JSON.parse(localStorage.getItem('materiales') ?? '[]')
        },
        ordenCompra(){
            return JSON.parse(localStorage.getItem('orden_compra') ?? '{}')
        },
        // proveedores(){
        //     return this.materiales
        //     ?.map(material =>  material.proveedores
        //     ?.map(proveedor => ({...proveedor, id: material.id})) )
        //     ?.flat()
        //     ?.filter(proveedor => proveedor.cantidad != 0) 
        //     ?? []
        // },
        // bodegas(){
        //     return this.materiales
        //     ?.map(material =>  material.bodegas
        //     ?.map(bodega => ({...bodega, id: material.id})) )
        //     ?.flat()
        //     ?? []
        // },
        indiceProveedores(){
            return JSON.parse(localStorage.getItem('proveedores') ?? '[]')
        },
        indiceBodegas(){
            return JSON.parse(localStorage.getItem('bodegas') ?? '[]')
        },
        productName(){
            return this.ordenCompra?.producto?.nombre ?? '-'
        },
        oportunity(){
            return this.ordenCompra?.matriz?.oportunidad?.oportunidad ?? '-'
        },
        matrizDate(){
            return this.ordenCompra?.matriz?.fecha ?? '-' 
        },
        matrizPlace(){
            return this.ordenCompra?.matriz?.lugar ?? '-'
        },
        description(){
            return this.ordenCompra?.matriz?.descripcion ?? '-'
        },


    },
    methods: {
        ...mapActions({
            crearOrdenCompra: 'compras/ordenes_compra/Action_create_orden_compra',
            Action_get_valor_materiales_proveedores: 'obras/ordenesCompras/Action_get_valor_materiales_proveedores'
        }),
        mostrarProveedor(){
            let allOk = false
            this.proveedores.forEach(el=>{
                this.materiales.forEach(ele=>{
                    if(ele.cantidad > 0){
                        this.allOk = true
                    }
                })
                this.allOk=false
            })
            return allOk
        },
        cantidadMateriales(materiales){
            let total = 0
            materiales.forEach(el => {
                total += el.cantidad
            });
            return total
        },
        calcularValorMateriales(materiales){
            let total = 0
            materiales.forEach(el => {
                total += el.cantidad * el.valor
            });
            return total
        },
        valorTotalProveedores(){
            let total = 0
            this.proveedores_select.forEach(el => {
                el.materiales.forEach(ele => {
                const valTotal =  ele.cantidad * ele.valor
                total += valTotal
                })
            })
            return total
        },
        valorTotalBodegas(){
            let total = 0
            this.bodegas.forEach(el => {
                el.materiales.forEach(ele => {
                const valTotal =  ele.cantidad * ele.valor
                total += valTotal
                })
            })
            return total
        },
        cantidadMaterialesBodega(materiales){
            let total = 0
            materiales.forEach(el => {
                total += el.cantidad
            });
            return total
        },
        async toggle(proveedores, bodegas){
            await this.Action_get_valor_materiales_proveedores({proveedores: proveedores, bodegas: bodegas})
            this.proveedores = this.proveedores_select.filter(el => el.materiales.some(mat => mat.cantidad > 0))
            this.bodegas = this.proveedores_select.filter(el => (el.materiales.some(mat => mat.cantidad > 0) && el.disponible))
            this.$refs.modalSendOc.toggle()

        },
        async accept(){
            this.$refs.modalSendOc.toggle()

            const payload = {
                ...this.ordenCompra.matriz,
                id_oportunidad: this.ordenCompra.matriz.oportunidad.id,
                id_producto: this.ordenCompra.producto.id,
                tabla: this.ordenCompra.producto.tabla,
                oportunidad: null,
                proveedores: this.proveedores,
                bodegas: this.bodegas,
                fecha:moment(this.ordenCompra.matriz.fecha).format('YYYY-MM-DD')
            }

            await this.crearOrdenCompra(payload)

            this.$router.push({ name: 'compras.matrix' })
        },
    },
    mounted(){
        this.valorTotal = this.proveedores.reduce((prev, curr) => {
            if(prev.valor_uni && curr.valor_uni){
                prev = (parseInt(prev?.cantidad) * prev?.valor_uni) + (parseInt(curr?.cantidad) * parseInt(curr?.valor_uni))
                return prev
            } else {
                return 0
            }
        }, {})
    }
}
</script>

<style lang="scss" scoped>
.enviar-ordenes{
    .info-general{
        background: #D4D4D4;
    }
}
</style>
